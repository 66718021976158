@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&family=Permanent+Marker&family=Square+Peg&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nova+Round&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    font-family: 'Montserrat', sans-serif;
    background-color: #3a3a3c;
}

/* .qoute {
    animation: mover 1s infinite alternate;
    color:#2a323d;
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-8px);
    }
} */